import { Show } from '#components'
import { endpoints, baseRequest, headers, usePopSchema } from '#hooks'
import { defaultValues, fieldType } from '#hooks/usePopSchema'
import { pagesSchemaType, postsSchemaType } from '#services/schemaTypes'
import { formatDate, parseDate } from '#helpers'
import {
  Button,
  DatePicker,
  Flex,
  Icon,
  Popover,
  SelectControl,
  Spinner,
  TextControl,
  TextareaControl,
} from '@wordpress/components'
import {
  __experimentalInputControl as InputControl,
  __experimentalInputControlSuffixWrapper as InputControlSuffixWrapper,
} from '@wordpress/components'
import { useEffect, useState } from '@wordpress/element'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import './styles.css'

const InputDatePicker = ({
  label,
  placeholder,
  field,
  setValue,
  className,
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [date, setDate] = useState(field.value)

  const handleDateChange = (newDate) => {
    setDate(formatDate(newDate))
    setValue(field.name, formatDate(newDate))
    setShowDatePicker(false)
  }

  return (
    <InputControl
      className={className}
      label={label}
      placeholder={placeholder}
      suffix={
        <InputControlSuffixWrapper>
          <Icon
            icon="calendar"
            onClick={() => setShowDatePicker(!showDatePicker)}
          />
          <Show when={showDatePicker}>
            <Popover>
              <div style={{ padding: '10px' }}>
                <DatePicker
                  currentDate={parseDate(date)}
                  onChange={handleDateChange}
                />
              </div>
            </Popover>
          </Show>
        </InputControlSuffixWrapper>
      }
      value={date}
      {...field}
    />
  )
}

const InputByType = ({
  type,
  field,
  label,
  placeholder,
  setValue,
  className,
}) => {
  switch (type) {
    case 'date':
      return (
        <InputDatePicker
          className={className}
          label={label}
          placeholder={placeholder}
          field={field}
          setValue={setValue}
        />
      )
    case 'textarea':
      return (
        <TextareaControl
          className={className}
          label={label}
          placeholder={placeholder}
          {...field}
        />
      )
    default:
      return (
        <TextControl
          className={className}
          label={label}
          placeholder={placeholder}
          {...field}
        />
      )
  }
}

export const Schema = ({ pop }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [responseMessage, setResponseMessage] = useState(null)
  const { t } = useTranslation()
  const {
    data: popSchema,
    isLoading,
    isValidating,
    mutate,
    error,
  } = usePopSchema({
    popId: pop.id,
    key: 'structuredData',
  })

  const { handleSubmit, control, watch, setValue } = useForm({
    values: {
      type: popSchema.type,
      data: [popSchema.data],
    },
  })
  const { fields, replace } = useFieldArray({
    control,
    shouldUnregister: true,
    name: 'data',
  })

  const generateOptions = (types) =>
    types.map((type) => ({
      label: t(`sidebar.schema.input.type.${type.toLowerCase()}`),
      value: type,
    }))

  const schemaType = [
    ...generateOptions(pagesSchemaType),
    ...generateOptions(postsSchemaType),
  ]

  const onSubmit = ({ data: [formValues], type }) => {
    setIsBusy(true)

    const { data, method, url } = endpoints.meta.create({
      postId: pop.id,
      data: { structuredData: { type, ...formValues } },
    })

    fetch(`/wp-json/${url}`, {
      method,
      headers,
      body: JSON.stringify(data),
    })
      .then(() => {
        mutate()
      })
      .then(() => {
        setResponseMessage({
          result: 'success',
          text: t('sidebar.schema.save.notification.success'),
        })
      })
      .catch(() => {
        setResponseMessage({
          result: 'error',
          text: t('sidebar.schema.save.notification.error'),
        })
      })
      .finally(() => {
        setIsBusy(false)
      })
  }

  const changeSchemaType = (schemaType) => {
    replace([
      {
        ...defaultValues[schemaType],
        ...(popSchema.type === schemaType && popSchema.data),
      },
    ])
  }

  useEffect(() => {
    setIsBusy(isLoading || isValidating)
  }, [isLoading, isValidating])

  useEffect(() => {
    if (error) {
      setResponseMessage({
        result: 'error',
        text: t('sidebar.schema.save.notification.error'),
      })
    }
  }, [error])

  if (isLoading) {
    return (
      <Flex justify="center" style={{ height: '50vh' }}>
        <Spinner
          style={{
            height: '60px',
            width: '60px',
            position: 'initial',
          }}
        />
      </Flex>
    )
  }

  return (
    <div className="pop-schema">
      <b>
        <p>{t('sidebar.schema.title')}</p>
      </b>
      <p>{t('sidebar.schema.text')}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <SelectControl
              {...field}
              label={t('sidebar.schema.input.type.label')}
              options={schemaType}
              onChange={(value) => {
                field.onChange(value)
                changeSchemaType(value)
              }}
            />
          )}
        />
        {fields.map((field) => {
          const filteredFields = Object.entries(field).filter(
            ([key]) => key !== 'id',
          )
          return (
            <fieldset key={field.id}>
              {filteredFields.map(([fieldName], index) => (
                <Controller
                  key={`${fieldName}-${index}`}
                  name={`data.0.${fieldName}`}
                  control={control}
                  render={({ field }) => (
                    <InputByType
                      className="pop-schema--input"
                      type={fieldType(fieldName)}
                      field={field}
                      label={t(
                        `sidebar.schema.input.${watch('type').toLowerCase()}.${fieldName.toLowerCase()}.label`,
                      )}
                      placeholder={t(
                        `sidebar.schema.input.${watch('type').toLowerCase()}.${fieldName.toLowerCase()}.placeholder`,
                      )}
                      setValue={setValue}
                    />
                  )}
                />
              ))}
            </fieldset>
          )
        })}
        <Button
          isBusy={isBusy}
          disabled={isBusy}
          variant="primary"
          type="submit"
        >
          {t('sidebar.schema.save')}
        </Button>
        <Show when={responseMessage}>
          <p className={`response-message--${responseMessage?.result}`}>
            {responseMessage?.text}
          </p>
        </Show>
      </form>
    </div>
  )
}
