import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '.'

export const defaultValues = {
  WebPage: {
    lastReviewed: '',
    author: '',
  },
  AboutPage: { lastReviewed: '' },
  FAQPage: { lastReviewed: '' },
  ContactPage: { lastReviewed: '' },
  Article: {
    dateModified: '',
    author: '',
    abstract: '',
  },
  Product: {
    name: '',
    brand: '',
    category: '',
    model: '',
    color: '',
    size: '',
    offers: '',
    description: '',
    image: '',
  },
  Recipe: {
    name: '',
    author: '',
    image: '',
    cookTime: '',
    recipeCategory: '',
    ingredients: '',
    recipeInstructions: '',
  },
  Event: {
    name: '',
    about: '',
    duration: '',
    location: '',
    startDate: '',
    offers: '',
  },
}

export const fieldType = (field) => {
  const textArea = ['about', 'abstract', 'description', 'recipeInstructions']
  const date = ['lastReviewed', 'dateModified', 'startDate']

  if (textArea.includes(field)) {
    return 'textarea'
  } else if (date.includes(field)) {
    return 'date'
  } else {
    return 'text'
  }
}

export const usePopSchema = ({ popId, key }) => {
  const { data, isLoading, isValidating, error, mutate } = useSWRImmutable(
    popId && key ? endpoints.meta.read({ popId, key }) : null,
    request,
  )

  const { type, ...properties } = data?.data?.data || {
    type: 'WebPage',
    ...defaultValues.WebPage,
  }

  return {
    data: { type, data: properties },
    isLoading,
    isValidating,
    error,
    mutate,
  }
}
