import { Show, UpgradeModal } from '#components'
import { log, removeSpecialChars } from '#helpers'
import { endpoints, request, useMeta, usePop, useProductDetails } from '#hooks'
import { Button, Flex } from '@wordpress/components'
import { useState } from '@wordpress/element'
import { useTranslation } from 'react-i18next'

export const AIGenerationButton = ({ watch, setValue, pop }) => {
  const { t } = useTranslation()
  const { data: post } = usePop(pop)
  const { data: productInfo } = useProductDetails()
  const { data: keyword } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })

  const [isWaiting, setIsWaiting] = useState(false)
  const [triesLeft, setTriesLeft] = useState(null)
  const [isSameContent, setIsSameContent] = useState(false)
  const [hasMaxTries, setHasMaxTries] = useState(false)

  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const aiSuggestions = productInfo?.features.aiSuggestions

  const generateMetaTags = () => {
    if (productInfo?.free) {
      setShowUpgradeModal(true)
    } else {
      setIsWaiting(true)
      request(
        endpoints.metatagsGenerator.create({
          url: post.link,
          ...(keyword && {
            keyword,
          }),
        }),
      )
        .then((response) => {
          if (response.data.errors) {
            if (response.data.errors[0].code === 403) {
              setHasMaxTries(true)
              setIsSameContent(false)
              setTriesLeft(0)
            }
            throw new Error(
              `Error code ${response.data.errors[0].code}: ${response.data.errors[0].title}`,
            )
          }
          if (
            removeSpecialChars(response.data.response.description) ===
              removeSpecialChars(watch('description')) ||
            removeSpecialChars(response.data.response.title) ===
              removeSpecialChars(watch('title'))
          ) {
            setIsSameContent(true)
          } else {
            setIsSameContent(false)
            setValue('title', response.data.response.title)
            setValue('description', response.data.response.description)
          }
          setTriesLeft(response.data.triesLeft)
        })
        .finally(() => {
          setIsWaiting(false)
        })
        .catch(log)
    }
  }

  return (
    <>
      <Button
        onClick={generateMetaTags}
        isBusy={isWaiting}
        disabled={isWaiting || !aiSuggestions}
        variant="primary"
        className="is-pro"
      >
        {t('sidebar.task.metadata.modaledit.button.generateAI')}
      </Button>
      <Flex wrap={true} align="flex-start" gap="1">
        <p className={isSameContent || hasMaxTries ? 'error-message' : ''}>
          <Show when={aiSuggestions && triesLeft}>
            <span>
              {t('sidebar.task.addtitle.field.helper.counter', {
                tries: aiSuggestions - triesLeft || 0,
                maxtries: aiSuggestions,
              })}
            </span>
          </Show>
          <Show when={isSameContent}>
            <span> {t('sidebar.task.addtitle.field.helper.samecontent')}</span>
          </Show>
          <Show when={hasMaxTries}>
            <span> {t('sidebar.task.addtitle.field.helper.maxtries')}</span>
          </Show>
        </p>
      </Flex>
      <Show when={showUpgradeModal}>
        <UpgradeModal closeModal={() => setShowUpgradeModal(false)} />
      </Show>
    </>
  )
}
