import { Show, TokenLabel } from '#components'
import { log } from '#helpers'
import { endpoints, request, useMeta } from '#hooks'
import {
  Button,
  ButtonGroup,
  Disabled,
  Flex,
  Modal,
} from '@wordpress/components'
import { useState } from '@wordpress/element'
import { useTranslation } from 'react-i18next'

export const Selected = ({ pop }) => {
  const { t } = useTranslation()
  const { data: keywordSelected, mutate: mutateMeta } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })

  const [isWaiting, setIsWaiting] = useState(false)

  const [showRemoveKeywordModal, setShowRemoveKeywordModal] = useState(false)
  const openRemoveKeywordModal = () => setShowRemoveKeywordModal(true)
  const closeRemoveKeywordModal = () => setShowRemoveKeywordModal(false)

  const removeKeyword = () => {
    setIsWaiting(true)
    request(
      endpoints.meta.create({
        postId: pop.id,
        data: {
          keyword: '',
          keywordId: '',
        },
      }),
    )
      .finally(() => {
        setIsWaiting(false)
        closeRemoveKeywordModal()
        mutateMeta()
      })
      .catch(log)
  }

  return (
    <>
      <div className="focus-keyword selected">
        <p className="focus-keyword__title">
          {t('sidebar.task.keyword.label')}
        </p>
        <div className="focus-keyword__notice">
          <TokenLabel onClick={openRemoveKeywordModal}>
            {keywordSelected}
          </TokenLabel>
        </div>
      </div>
      <Show when={showRemoveKeywordModal}>
        <Modal
          title={t('sidebar.task.keyword.modal.remove.title')}
          className="mktseo mktseo-modal--black"
          shouldCloseOnClickOutside={false}
          onRequestClose={closeRemoveKeywordModal}
        >
          <p>{t('sidebar.task.keyword.modal.remove.content1')}</p>
          <p>{t('sidebar.task.keyword.modal.remove.content2')}</p>
          <ButtonGroup>
            <Flex gap="5" justify="end">
              <Button
                isDestructive
                onClick={closeRemoveKeywordModal}
                variant="secondary"
              >
                {t('sidebar.task.keyword.modal.remove.button.cancel')}
              </Button>
              <Button
                isBusy={isWaiting}
                disabled={isWaiting}
                onClick={removeKeyword}
                isDestructive
                variant="primary"
              >
                {t('sidebar.task.keyword.modal.remove.button.remove')}
              </Button>
            </Flex>
          </ButtonGroup>
        </Modal>
      </Show>
    </>
  )
}
