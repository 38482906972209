import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '.'

export const usePop = ({ id, type }) => {
  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(
    id ? endpoints.pop.get({ id, type }) : null,
    request,
  )

  return {
    data: data?.data,
    isError: error,
    isLoading,
    mutate,
    isValidating,
  }
}
