import './divi-styles.css'
import './translations/i18next'
import { Tasks } from './TasksList'
import { render, useContext } from '@wordpress/element'
import { Draggable } from '#components/Draggable'

const SettingsButton = ({ onClick, children }) => {
  const className = [
    'et-fb-button',
    'et-fb-button--elevate',
    'et-fb-button--primary',
    'et-fb-button--round',
  ].join(' ')
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  )
}

window.addEventListener('message', function (event) {
  if ('et_builder_api_ready' === event.data.eventType) {
    const modalId = document.getElementById('marketgoo-app-modal')
    const settingsButtonId = document.getElementById('marketgoo-settings')

    const buttonGroup = document.querySelector(
      '.et-fb-page-settings-bar__column--main .et-fb-button-group',
    )
    buttonGroup.append(settingsButtonId)

    if (settingsButtonId) {
      render(
        <SettingsButton onClick={() => modalId.classList.toggle('visible')}>
          <img
            src={`${window.marketgoo?.partnerPath}/wordpress/assets/${
              window.marketgoo?.partnerkey !== 'dreamhost'
                ? 'mktgoo'
                : 'dreamhost'
            }/img/icon-128.png`}
          />
        </SettingsButton>,
        settingsButtonId,
      )
    }

    if (modalId) {
      render(
        <Draggable>
          <Tasks />
        </Draggable>,
        modalId,
      )
    }
  }
})
