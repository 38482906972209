import useSWRImmutable from 'swr/immutable'
import { endpoints, request } from '.'

export const useSearchAppearance = ({ id, type }) => {
  const description = useSWRImmutable(
    id ? endpoints.searchAppearanceDescription.get({ id, type }) : null,
    request,
  )

  const title = useSWRImmutable(
    id ? endpoints.searchAppearanceTitle.get({ id, type }) : null,
    request,
  )
  return {
    data: {
      description: description.data?.data?.data?.description,
      title: title.data?.data?.data?.title,
    },
    isError: description.error || title.error,
    isLoading: description.isLoading || title.isLoading,
    mutate: () => {
      description.mutate()
      title.mutate()
    },
    isValidating: description.isValidating || title.isValidating,
  }
}
