import { endpoints, request } from '#hooks'

export const getPop = async (setPop) => {
  const params = new URLSearchParams(window.location.search)
  const [slug] = window.location.pathname
    .split('/')
    .filter((str) => str.length)
    .slice(-1)

  const isPost = wp.data?.select('core/editor')?.getCurrentPostType() === 'post'
  const isPage = wp.data?.select('core/editor')?.getCurrentPostType() === 'page'
  const isPublished =
    wp.data?.select('core/editor')?.getCurrentPostAttribute('status') ===
    'publish'

  if (isPublished && (isPost || isPage)) {
    const { id, type, status } = wp.data.select('core/editor').getCurrentPost()
    setPop({ id, type, status })
  } else if (params.get('post')) {
    try {
      const response = await Promise.allSettled([
        request(endpoints.pages.get(params.get('post'))),
        request(endpoints.posts.get(params.get('post'))),
      ])

      const {
        value: {
          data: { type, id, status },
        },
      } = response.find(({ status }) => status === 'fulfilled')
      setPop({ id, type, status })
    } catch (error) {
      console.log(error)
    }
  } else if (slug !== 'post-new.php') {
    const response = await Promise.allSettled([
      request({ ...endpoints.pages.get(), ...{ params: { slug } } }),
      request({ ...endpoints.posts.get(), ...{ params: { slug } } }),
    ])

    const {
      value: {
        data: [{ type, id, status }],
      },
    } = response.find(
      ({ status, value: { data } }) => status === 'fulfilled' && data.length,
    )
    setPop({ id, type, status })
  }
}
