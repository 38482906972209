import { Details, Show, Tooltip } from '#components'
import { log } from '#helpers'
import { endpoints, request, useMeta, usePop, useScan } from '#hooks'
import { currentLocale } from '#translations/i18next'
import {
  Button,
  ButtonGroup,
  Disabled,
  Flex,
  Icon,
  Modal,
  PanelRow,
  Spinner,
  TextControl,
} from '@wordpress/components'
import { useState } from '@wordpress/element'
import { helpFilled } from '@wordpress/icons'
import { Controller, useForm } from 'react-hook-form'
import { Trans as T, useTranslation } from 'react-i18next'

import './styles.css'

export const Unselected = ({ pop }) => {
  const { t } = useTranslation()
  const { mutate: mutateMeta } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })
  const { mutate: mutateScan } = useScan(pop)
  const { data: post } = usePop(pop)

  const OPTIONS_SHOWN = 5

  const [isWaiting, setIsWaiting] = useState(false)

  const [showSearchKeywordModal, setShowSearchKeywordModal] = useState(false)
  const openSearchKeywordModal = () => setShowSearchKeywordModal(true)
  const closeSearchKeywordModal = () => setShowSearchKeywordModal(false)

  const [showSelectKeywordModal, setShowSelectKeywordModal] = useState(false)
  const openSelectKeywordModal = () => setShowSelectKeywordModal(true)
  const closeSelectKeywordModal = () => setShowSelectKeywordModal(false)

  const [options, setOptions] = useState([])

  const defaultValues = { keywords: '', option: '' }
  const { handleSubmit, control, reset, register, watch, setValue } = useForm({
    defaultValues,
  })

  const searchKeywords = (data) => {
    setIsWaiting(true)
    closeSearchKeywordModal()
    openSelectKeywordModal()
    request(endpoints.keywordSearch.get({ keywords: data.keywords }))
      .then(({ data: { data, success } }) => {
        if (success) {
          const [first, ...others] = data.attributes.results
          setOptions([
            {
              label: first.name,
              value: first.name,
              searchVolume: first.searchVolume,
              id: first.id,
            },
            ...others
              .map((result) => ({
                label: result.name,
                value: result.name,
                searchVolume: result.searchVolume,
                id: result.id,
              }))
              .sort((a, b) => a.searchVolume < b.searchVolume),
          ])
        }
      })
      .then(() => {
        setValue('option', data.keywords)
      })
      .finally(() => {
        setIsWaiting(false)
      })
      .catch(log)
  }

  const sendKeywords = (data) => {
    setIsWaiting(true)
    const result = options.find(({ value }) => value === data.option)
    request(
      endpoints.meta.create({
        postId: pop.id,
        data: {
          keyword: result.value,
          keywordId: result.id,
        },
      }),
    )
      .finally(() => {
        setIsWaiting(false)
        closeSelectKeywordModal()
        setOptions([])
        reset(defaultValues)
        mutateScan()
        mutateMeta()
      })
      .catch(log)
  }

  const textCleaner = (htmlText) => {
    const tempElement = document.createElement('div')
    tempElement.innerHTML = htmlText
    return tempElement.textContent || tempElement.innerText || ''
  }

  return (
    <PanelRow>
      <div className="focus-keyword unselected">
        <p className="focus-keyword__title">
          {t('sidebar.task.keyword.title')}
        </p>
        <p>{t('sidebar.task.keyword.description')}</p>
        <Button onClick={openSearchKeywordModal} variant="primary">
          {t('sidebar.task.keyword.button')}
        </Button>
        <Details summary={t('sidebar.task.keyword.question')}>
          <p>{t('sidebar.task.keyword.answer')}</p>
        </Details>
        <Show when={showSearchKeywordModal}>
          <Modal
            title={t('sidebar.task.keyword.modal.select.title')}
            className="mktseo mktseo-modal--black"
            shouldCloseOnClickOutside={false}
            onRequestClose={closeSearchKeywordModal}
          >
            <form onSubmit={handleSubmit(searchKeywords)}>
              <p> {t('sidebar.task.keyword.modal.search.content1')} </p>
              <p>
                {t('sidebar.task.keyword.modal.search.content2', {
                  title: textCleaner(post?.title?.rendered),
                })}
              </p>
              <Controller
                name="keywords"
                control={control}
                render={({ field, fieldState }) => (
                  <TextControl
                    {...field}
                    placeholder={t(
                      'sidebar.task.keyword.modal.search.input.placeholder',
                    )}
                    className={fieldState.invalid ? 'form-error' : ''}
                    help={fieldState.error?.message || null}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t('sidebar.task.keyword.modal.search.input.error'),
                  },
                }}
              />
              <ButtonGroup>
                <Flex gap="5" justify="end">
                  <Button type="submit" variant="primary">
                    {t('sidebar.task.keyword.modal.search.button')}
                  </Button>
                </Flex>
              </ButtonGroup>
            </form>
          </Modal>
        </Show>
        <Show when={showSelectKeywordModal}>
          <Modal
            title={t('sidebar.task.keyword.modal.select.title')}
            className="mktseo mktseo-modal--black"
            shouldCloseOnClickOutside={false}
            onRequestClose={closeSelectKeywordModal}
          >
            <p>{t('sidebar.task.keyword.modal.select.content1')}</p>
            <p>
              <T
                i18nKey="sidebar.task.keyword.modal.select.content2"
                values={{ search: watch('keywords') }}
                components={[<strong key={0} />]}
              />
            </p>
            <Show when={isWaiting && !options.length}>
              <Spinner />
            </Show>
            <Show when={options.length}>
              <form onSubmit={handleSubmit(sendKeywords)}>
                <table className="focus-keyword__table">
                  <thead>
                    <tr>
                      <th>
                        {t('sidebar.task.keyword.modal.select.table.keywords')}
                      </th>
                      <th>
                        {t('sidebar.task.keyword.modal.select.table.volume')}
                        <Tooltip
                          text={t(
                            'sidebar.task.keyword.modal.select.table.volume.tooltip',
                          )}
                        >
                          <Icon icon={helpFilled} />
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {options.slice(0, OPTIONS_SHOWN).map((result) => (
                      <tr
                        key={result.id}
                        className={
                          watch('option') === result.value ? 'checked' : ''
                        }
                        onClick={() => {
                          setValue('option', result.value)
                        }}
                      >
                        <td>
                          <input
                            {...register('option')}
                            value={result.value}
                            type="radio"
                          />
                          {result.value}
                        </td>
                        <td>
                          {Intl.NumberFormat(currentLocale, {
                            notation: 'compact',
                            compactDisplay: 'short',
                          }).format(result.searchVolume)}
                        </td>
                      </tr>
                    ))}
                    {options.length < OPTIONS_SHOWN
                      ? [...new Array(OPTIONS_SHOWN - options.length)].map(
                          (_, index) => (
                            <tr className="empty" key={index}>
                              <td>
                                <input type="radio" disabled />
                                {t(
                                  'sidebar.task.keyword.modal.select.table.empty',
                                )}
                              </td>
                              <td>0</td>
                            </tr>
                          ),
                        )
                      : null}
                  </tbody>
                </table>
                <Show when={options.length < 6}>
                  <p>{t('sidebar.task.keyword.modal.select.table.helper')}</p>
                </Show>
                <ButtonGroup>
                  <Flex gap="5" justify="end">
                    <Button
                      isBusy={isWaiting}
                      disabled={isWaiting}
                      onClick={() => {
                        closeSelectKeywordModal()
                        openSearchKeywordModal()
                        setOptions([])
                        setValue('option', '')
                      }}
                      variant="secondary"
                    >
                      {t('sidebar.task.keyword.modal.select.button.edit')}
                    </Button>
                    <Button
                      isBusy={isWaiting}
                      disabled={!watch('option') || isWaiting}
                      type="submit"
                      variant="primary"
                    >
                      {t('sidebar.task.keyword.modal.select.button.add')}
                    </Button>
                  </Flex>
                </ButtonGroup>
              </form>
            </Show>
          </Modal>
        </Show>
      </div>
    </PanelRow>
  )
}
